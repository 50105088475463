<template>
  <div class="wrapper" id="diyMenu">
    <ctheader></ctheader>
    <div class="content" ref="diyMenu">
      <div class="sub-title">企业LOGO</div>
      <div class="field-div">
        <div class="imgs" v-if="imgUrl">
          <i class="el-icon-error" @click="delPreImage"></i>
          <img :src="imgUrl" />
        </div>
        <file-upload
          v-model="files"
          :post-action="postAction"
          :data="excelData"
          :size="size"
          :timeout="timeout"
          @input-file="inputFile"
          ref="upload"
        >
          <div class="import">上传图片</div>
        </file-upload>
      </div>
      <div class="sub-title">企业名称</div>
      <div class="field-div">
        <input class="w150" type="text" v-model="name" maxlength="7" />
      </div>
      <!-- <div class="sub-title">
        背景设置
        <button class="btn line-primary" @click="resetColor">
          还原默认颜色设置
        </button>
      </div> -->
      <!-- <div class="field-div">
        <div class="con-title">MIS菜单背景色</div>
        <el-color-picker v-model="color.bg"></el-color-picker>
        <div class="con-title">MIS菜单字体色</div>
        <el-color-picker v-model="color.fc"></el-color-picker>
        <div class="con-title">MIS菜单选中背景色</div>
        <el-color-picker v-model="color.bgActive"></el-color-picker>
        <div class="con-title">MIS菜单选中字体色</div>
        <el-color-picker v-model="color.fcActive"></el-color-picker>
      </div> -->
      <div class="sub-title">菜单设置</div>
      <div class="field-div">
        <div class="con-title">MIS公司端</div>
        <div class="native-table">
          <table>
            <!-- <thead>
              <tr>
                <th>一级菜单</th>
                <th>二级菜单</th>
                <th>三级菜单</th>
              </tr>
            </thead> -->
            <tbody>
              <div v-for="(row, index) in company_menu" :key="index">
                <template v-if="row.children.length > 0">
                  <tr v-for="(item, eindex) in row.children" :key="eindex">
                    <td :rowspan="row.children.length" v-if="eindex === 0">
                      <input
                        class="w150"
                        type="text"
                        v-model="row.title"
                        maxlength="7"
                      />
                    </td>
                    <td>
                      <input
                        class="w150"
                        type="text"
                        v-model="item.title"
                        maxlength="7"
                      />
                    </td>
                    <td>
                      <div class="children-item">
                        <input
                          class="w150"
                          type="text"
                          v-for="(items, index) in item.children"
                          :key="index"
                          v-model="items.title"
                          maxlength="8"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="3">
                    <input
                      class="w150"
                      type="text"
                      v-model="row.title"
                      maxlength="7"
                    />
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
        <!-- <div class="menu-content" v-for="row in company_menu">
					<div class="father-menu">
						<input class="w150" type="text" v-model="row.title" maxlength="7">
					</div>
					<div class="children-menu">
						<div class="children-item" v-for="item in row.children"><span>{{item.title}}</span><input class="w150" type="text" v-for="items in item.children" v-model="items.title" maxlength="8"></div>
					</div>
				</div> -->
      </div>
      <div class="field-div bottom-field-div">
        <div class="con-title">MIS市场端</div>
        <div class="native-table">
          <table>
            <!-- <thead>
              <tr>
                <th>一级菜单</th>
                <th>二级菜单</th>
                <th>三级菜单</th>
              </tr>
            </thead> -->
            <tbody>
              <div v-for="(row, index) in mis_menu" :key="index">
                <template v-if="row.children.length > 0">
                  <tr v-for="(item, eindex) in row.children" :key="eindex">
                    <td :rowspan="row.children.length" v-if="eindex === 0">
                      <input
                        class="w150"
                        type="text"
                        v-model="row.title"
                        maxlength="7"
                      />
                    </td>
                    <td v-if="item.type != 3">
                      <input
                        class="w150"
                        type="text"
                        v-model="item.title"
                        maxlength="7"
                      />
                    </td>
                    <td v-if="item.type != 3">
                      <div class="children-item">
                        <input
                          class="w150"
                          type="text"
                          v-for="(items, index) in item.children"
                          :key="index"
                          v-model="items.title"
                          maxlength="8"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="3">
                    <input
                      class="w150"
                      type="text"
                      v-model="row.title"
                      maxlength="7"
                    />
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
        <!-- <div class="menu-content" v-for="row in mis_menu">
					<div class="father-menu">
						<input class="w150" type="text" v-model="row.title" maxlength="7">
					</div>
					<div class="children-menu">
						<div class="children-item" v-for="item in row.children" v-if="item.type + '' === '1'"><span>{{item.title}}</span><input class="w150" type="text" v-for="items in item.children" v-model="items.title" maxlength="8"></div>
					</div>
				</div> -->
      </div>
      <btns :save-callback="save" :cancel-callback="cancel" :init-callback="init" />
    </div>
  </div>
</template>

<script>
/**
 * @module app/customer/diyMenu
 * @desc 传蔬农贸版 设置自定义菜单
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-08-15
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
import fileUpload from "vue-upload-component";
import { apiPost, parse } from "../../modules/utils";
import * as URL from "../../modules/URLs";
import ctheader from "../../components/ctheader";
import btns from "./components/btns";
// import ctheader from '@@/ctheader';
// import btns from '@@/btns';
import APIs from "../../modules/APIs";
// import router from '@/router';
const getters = mapGetters([]);
const actions = mapActions(["setAlert"]);

const COLOR_ORI = {
  bg: "#151c2a",
  fc: "#6b7791",
  bgActive: "#104aa3",
  fcActive: "#fff",
};

export default {
  name: "diyMenu",
  computed: {
    ...getters,
  },
  components: { ctheader, fileUpload, btns },
  beforeRouteEnter(to, from, next) {
    let code = to.query.code;
    console.log(code);
    // to.meta.appTitle[1].path = `${URL.CUSTOMER}/${to.query.id}?code=${code}`;
    apiPost({
      url: APIs.MENU,
      data: {
        action: "company.menu.list",
        hq_code: code,
      },
      success(res) {
        next((vm) => {
          if (res.code === "0") {
            vm.company_menu = res.data.company_menu.map((el) => {
              if (!el.title) {
                el.title = el.title;
              }
              if (el.children && el.children.length !== 0) {
                el.children.map((sel) => {
                  if (!sel.title) {
                    sel.title = sel.title;
                  }
                  if (sel.children && sel.children.length !== 0) {
                    sel.children.map((ssel) => {
                      if (!ssel.title) {
                        ssel.title = ssel.title;
                      }
                      return ssel;
                    });
                  }
                  return sel;
                });
              }
              return el;
            });
            vm.mis_menu = res.data.mis_menu.map((el) => {
              if (!el.title) {
                el.title = el.title;
              }
              if (el.children && el.children.length !== 0) {
                el.children.map((sel) => {
                  if (!sel.title) {
                    sel.title = sel.title;
                  }
                  if (sel.children && sel.children.length !== 0) {
                    sel.children.map((ssel) => {
                      if (!ssel.title) {
                        ssel.title = ssel.title;
                      }
                      return ssel;
                    });
                  }
                  return sel;
                });
              }
              return el;
            });
          }
          vm.hqCode = code;
        });
      },
      error(res) {
        next();
      },
    });
  },
  created() {
    this.hqCode = this.$route.query.code;
    this.getLogo();
  },
  data() {
    return {
      hqCode: "",
      company_menu: [], // 公司端菜单数据
      mis_menu: [], // 市场端菜单数据
      size: 1024 * 1024 * 3, // 设置上传的excel文件大小
      timeout: 6 * 1000, // 设置上传文件超时时间
      postAction: APIs.MENU, // 导入商品的接口地址
      excelData: { action: "company.logo.upload" }, // 导入商品的接口请求数据
      files: [], // 导入商品文件的信息
      imgUrl: "/images/altpic.png",
      disabled: false,
      color: parse(COLOR_ORI),
      name: "",
    };
  },
  methods: {
    ...actions,
    /**
     * 设置名称
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    inputSet(row) {},
    /**
     * 保存
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    save() {
      let $this = this;
      let arr = [];
      $this.savePic();
      // if(!this.imgUrl || this.imgUrl === '/images/altpic.png'){
      // 	this.setAlert({ msg: '请上传企业Logo', type: false });
      // 	return false;
      // }
      this.company_menu.forEach((el) => {
        if (!el.title) {
          this.setAlert({ msg: "请输入公司端自定义菜单名称", type: false });
        }
        arr.push({
          id: el.id,
          title: el.title,
          icon: "",
        });
        if (el.children && el.children.length !== 0) {
          el.children.forEach((sel) => {
            if (!sel.title) {
              this.setAlert({ msg: "请输入公司端自定义菜单名称", type: false });
            }
            arr.push({
              id: sel.id,
              title: sel.title,
              icon: "",
            });
            if (sel.children && sel.children.length !== 0) {
              sel.children.forEach((ssel) => {
                if (!ssel.title && !ssel.title) {
                  this.setAlert({
                    msg: "请输入公司端自定义菜单名称",
                    type: false,
                  });
                }
                arr.push({
                  id: ssel.id,
                  title: ssel.title,
                  icon: "",
                });
              });
            }
          });
        }
      });
      this.mis_menu.forEach((el) => {
        if (!el.title) {
          this.setAlert({ msg: "请输入市场端自定义菜单名称", type: false });
        }
        arr.push({
          id: el.id,
          title: el.title !== "" ? el.title : el.title,
          icon: "",
        });
        if (el.children && el.children.length !== 0) {
          el.children.forEach((sel) => {
            if (!sel.title && !sel.title) {
              this.setAlert({ msg: "请输入市场端自定义菜单名称", type: false });
            }
            arr.push({
              id: sel.id,
              title: sel.title,
              icon: "",
            });
            if (sel.children && sel.children.length !== 0) {
              sel.children.forEach((ssel) => {
                if (!ssel.title && !ssel.title) {
                  this.setAlert({
                    msg: "请输入市场端自定义菜单名称",
                    type: false,
                  });
                }
                arr.push({
                  id: ssel.id,
                  title: ssel.title,
                  icon: "",
                });
              });
            }
          });
        }
      });
      apiPost({
        url: APIs.MENU,
        data: {
          action: "save.customizable.menu",
          hq_code: this.hqCode,
          // menu_data: arr,
          company_menu: this.company_menu,
          mis_menu: this.mis_menu,
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            if ($this.imgUrl && $this.imgUrl !== "/images/altpic.png") {
              $this.savePic();
            } else {
              $this.cancel();
            }
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(res) {
          $this.setAlert({ msg: res.msg, type: false });
        },
      });
    },

    init() {
      console.log("111")
      let $this = this;
      apiPost({
        url: APIs.MENU,
        data: {
          action: "company.menu.list",
        },
        success(res) {

          if (res.code === "0") {
            $this.company_menu = res.data.company_menu.map((el) => {
              if (!el.title) {
                el.title = el.title;
              }
              if (el.children && el.children.length !== 0) {
                el.children.map((sel) => {
                  if (!sel.title) {
                    sel.title = sel.title;
                  }
                  if (sel.children && sel.children.length !== 0) {
                    sel.children.map((ssel) => {
                      if (!ssel.title) {
                        ssel.title = ssel.title;
                      }
                      return ssel;
                    });
                  }
                  return sel;
                });
              }
              return el;
            });
            $this.mis_menu = res.data.mis_menu.map((el) => {
              if (!el.title) {
                el.title = el.title;
              }
              if (el.children && el.children.length !== 0) {
                el.children.map((sel) => {
                  if (!sel.title) {
                    sel.title = sel.title;
                  }
                  if (sel.children && sel.children.length !== 0) {
                    sel.children.map((ssel) => {
                      if (!ssel.title) {
                        ssel.title = ssel.title;
                      }
                      return ssel;
                    });
                  }
                  return sel;
                });
              }
              return el;
            });
          }

      },
      });

      apiPost({
        url: APIs.MENU,
        data: {
          action: "company.logo.list",
        },
        success(res) {
          if (res.code === "0") {
            $this.$set($this, "name",   res.data.name)
            $this.$set($this, "imgUrl",  res.data.url)
          }
        },
      });

    },
    /**
     * 保存图片Logo
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    savePic() {
      let $this = this;
      apiPost({
        url: APIs.MENU,
        data: {
          action: "save.company.logo",
          hq_code: this.hqCode,
          // logo_data:
          //   this.imgUrl !== "/images/altpic.png"
          //     ? [{ type: 1, url: this.imgUrl.split("//")[1] }]
          //     : [],
          url: this.imgUrl.split("//")[1],
          name: this.name,
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.cancel();
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(res) {
          $this.setAlert({ msg: res.msg, type: false });
        },
      });
    },
    /**
     * 导入商品
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    cancel() {
      this.$router.go(-1);
    },
    /**
     * 导入商品
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    getLogo() {
      let $this = this;
      apiPost({
        url: APIs.MENU,
        data: {
          action: "company.logo.list",
          hq_code: this.hqCode,
        },
        success(res) {
          if (res.code === "0") {
            $this.name = res.data.name;
            $this.imgUrl = "//" + res.data.url;
            // if (res.data.logo_list.length) {
            //   // $this.imgUrl = "//" + res.data.logo_list[0].url;
            // } else {
            //   $this.imgUrl = "/images/altpic.png";
            // }
            // if (res.data.menu_color === "") {
            //   $this.color = parse(COLOR_ORI);
            // } else {
            //   $this.color = JSON.parse(res.data.menu_color);
            // }
          } else {
            $this.imgUrl = "/images/altpic.png";
            $this.color = parse(COLOR_ORI);
          }
        },
        error(res) {
          $this.imgUrl = "/images/altpic.png";
          $this.color = parse(COLOR_ORI);
        },
      });
    },
    /**
     * 导入商品
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-11
     */
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile) {
        // 开始上传
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);
        }

        // 上传进度
        if (newFile.progress !== oldFile.progress) {
        }

        // 上传错误
        if (newFile.error !== oldFile.error) {
          this.setAlert({ msg: "导入失败", type: false });
        }

        // 上传成功
        if (newFile.success !== oldFile.success) {
          if (newFile.response.code === "0") {
            this.setAlert({ msg: newFile.response.msg, type: true });
            console.log(newFile.response.data);
            this.imgUrl =
              "//" +
              newFile.response.data.domain +
              "/" +
              newFile.response.data.path;
          } else {
            this.setAlert({ msg: newFile.response.msg, type: false });
          }
        }
      }
    },
    delPreImage() {
      this.imgUrl = "";
    },
    resetColor() {
      this.color = parse(COLOR_ORI);
    },
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#diyMenu {
  .content {
    overflow-y: auto;
    .pdb(70px);
  }
  .sub-title {
    font-size: 16px;
    color: #a0b9db;
    .mgb(16px);
  }
  .con-title {
    font-size: 14px;
    color: #525e6e;
    .mgb(16px);
  }
  .field-div {
    .mgb(16px);
    .pdl(32px);
    .imgs {
      .wh(80px, 80px);
      position: relative;
      .el-icon-error {
        position: absolute;
      }
      .el-icon-error:hover {
        color: #e94e30;
      }
      img {
        .wh(80px, 80px);
      }
      // .bgc(red);
    }
    .import {
      .brd(1px, solid, #a0b9db);
      .pdl(14px);
      .pdr(14px);
      line-height: 30px;
      .fc(14px, #525e6e);
      .mgt(8px);
      .cs();
    }
    .import:hover {
      .fgc(#257873);
      .brd(1px, solid, #257873);
    }
    .menu-content {
      .mgb(8px);
      display: flex;
      .father-menu {
        .dib();
        .va-t();
      }
      .children-menu {
        .pdl(95px);
        .dib();
        flex: 1;
        .children-item {
          position: relative;
          span {
            position: absolute;
            left: -86px;
            top: 8px;
            .dib();
            .ta-c();
            .fc(14px, #525e6e);
            // .mgl(8px);
            // .mgr(8px);
          }
          input {
            .mgr(8px);
            .mgb(8px);
          }
        }
      }
    }
    .native-table {
      input {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
  .bottom-field-div {
    margin-bottom: 200px;
  }
  .native-table table tbody tr > td {
    vertical-align: top;
  }
}
</style>
